export interface Route {
    path: string;
    name: string;
    title: string;
    load: any;
}
export interface NavbarRoute extends Route {
    visibleInNavbar: boolean;
    iconName: string;
    disabled?: boolean;
}
export interface FallbackRoute {
    default: true;
    title: string;
    name: string;
    load: any;
    path: undefined;
}

export function isVisibleInNavbar(route: SomeRoute): route is NavbarRoute {
    return !!(route as NavbarRoute).visibleInNavbar;
}

export type SomeRoute = Route | NavbarRoute | FallbackRoute;

const routeSettings: SomeRoute[] = [
    {
        path: '/',
        name: 'dashboard',
        title: 'Nepal COVID-19 Dashboard',
        load: () => import('../views/Dashboard'),
        visibleInNavbar: false,
    },
    {
        name: 'returneeForm',
        title: 'Returnee Form',
        path: '/returnee-form/',
        load: () => import('../views/MigrantForm'),
        visibleInNavbar: false,
    },

    {
        name: 'formSubmitSuccess',
        title: 'Sucessfully submitted form',
        path: '/success/',
        load: () => import('../views/FormSubmitSuccess'),
        visibleInNavbar: false,
    },

    {
        name: 'situationReport',
        title: 'Situation Report',
        path: '/situation-report/',
        load: () => import('../views/SituationReport'),
        visibleInNavbar: false,
    },
    {
        name: 'timeline',
        title: 'Timeline',
        path: '/timeline/',
        load: () => import('../views/Timeline'),
        visibleInNavbar: false,
    },

    {
        name: 'about',
        title: 'About',
        path: '/about/',
        load: () => import('../views/About'),
        visibleInNavbar: false,
    },

    {
        name: 'fourHundredThree',
        title: '403',
        path: '/403/',
        load: () => import('../views/FourHundredThree'),
    },

    {
        name: 'fourHundredFour',
        title: '404',
        load: () => import('../views/FourHundredFour'),
        default: true,
        path: undefined,
    },
];

export default routeSettings;
