import React from 'react';
import { Theme } from '#constants/themes';

export interface ThemeContextProps {
    theme: Theme;
    setSelectedTheme: (theme: Theme) => void;
}

export default React.createContext<ThemeContextProps>({
    theme: 'light',
    setSelectedTheme: (theme: Theme) => {

    },
});
