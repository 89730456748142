import defaultColors from '#rsu/styles/default/colors';

const defaultStyleProperties = {
    colors: {
        ...defaultColors,
        colorPrimary: '#4060f4',
        colorAccent: '#7a7ae8',
        colorTextOnPrimary: '#ffffff',
        colorLink: '#03a9f4',
        colorBackgroundApp: '#e1e7f0',
        colorNationalRed: '#C90712',
        colorNationalBlue: '#3B5EFF',
        colorStroke: 'rgb(102, 102, 102)',
        colorGradientStartBackground: '#e6eefd',
        colorGradientEndBackground: '#ffffff',
        colorBoxShadow: '#0000001A',

        brightness: 1,
    },

    dimens: {
        widthContentMax: '900px',

        widthLeftPane: '20vw',
        minWidthLeftPane: '240px',
        widthRightPane: '26vw',
        minWidthRightPane: '320px',

        radiusBorderMedium: '6px',
        radiusBorderLarge: '8px',
        radiusBorderExtraLarge: '12px',

        widthScrollbar: '6px',
    },
};

export const darkStyleProperties = {
    colors: {
        colorPrimary: '#445dd2',
        colorAccent: '#b8b1fb',
        colorSeparator: 'rgba(255, 255, 255, 0.1)',
        colorSeparatorAlt: 'rgba(255, 255, 255, 0.2)',
        colorSeparatorHover: 'rgba(255, 255, 255, 0.3)',
        colorBackgroundApp: '#252523',
        colorBoxShadow: '#ffffff1A',
        colorForeground: '#1a1a1a',
        colorNationalRed: '#f27e7f',
        colorNationalBlue: '#79a3d8',
        colorText: '#ffffff',
        colorTextOnAccent: '#414141',
        colorBackgroundHover: '#414141',
        colorTextOnPrimary: '#faffff',
        colorTextLabel: '#bebebc',
        colorTextPlaceholder: '#bebebc',
        colorTextDisabled: '#aeaeac',
        colorBackgroundScrollbar: '#202020',
        colorForegroundScrollbar: '#333331',
        colorStroke: '#ffffff',
        colorGradientStartBackground: '#1da1f22e',
        colorGradientEndBackground: '#1a1a1a',
        colorBackgroundOverlay: 'rgba(0, 0, 0, 0.33)',

        colorBackgroundDisabled: '#0d0d0d',
        brightness: 0,
    },
};

export default defaultStyleProperties;
