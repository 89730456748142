import {
    setStyleProperties,
} from '#rsu/styles';

import defaultStyles, { darkStyleProperties } from '#constants/styleProperties';

export type Theme = 'light' | 'dark';

export interface ThemeDetail {
    key: Theme;
    title: string;
    colors: object;
    dimens: object;
}

interface Themes {
    light: ThemeDetail;
    dark: ThemeDetail;
}

export const themes: Themes = {
    light: {
        key: 'light',
        title: 'Light',
        colors: defaultStyles.colors,
        dimens: defaultStyles.dimens,
    },

    dark: {
        key: 'dark',
        title: 'Dark',
        colors: {
            ...defaultStyles.colors,
            ...darkStyleProperties.colors,
        },
        dimens: defaultStyles.dimens,
    },
};

export const themeKeySelector = (t: ThemeDetail) => t.key;
export const themeLabelSelector = (t: ThemeDetail) => t.title;

export const themesMap = Object.values(themes);

export const setTheme = (themeId: Theme) => {
    const theme = themes[themeId];

    setStyleProperties({
        ...theme.colors,
        ...theme.dimens,
    });
};
