import React, { useContext, useCallback } from 'react';
import { _cs } from '@togglecorp/fujs';
import { Link } from '@reach/router';
import Switch from 'react-switch';

// import ListView from '#rscv/List/ListView';
import { NormalSegmentInput as SegmentInput } from '#rsci/SegmentInput';

import LanguageContext from '#components/LanguageContext';
import Icon from '#rscg/Icon';
import ThemeContext from '#components/ThemeContext';
import t, {
    translate,
    langOptions,
    langKeySelector,
    langLabelSelector,
} from '#constants/translations';
// import { routeSettings, isVisibleInNavbar, NavbarRoute } from '#constants';
/*
import {
    themesMap,
    themeKeySelector,
    themeLabelSelector,
} from '#constants/themes';
*/

// import MenuItem from './MenuItem';
import logo from './emblem-of-nepal.png';

import styles from './styles.scss';

// const pages = routeSettings.filter(isVisibleInNavbar);
// const EmptyComponent = () => '';

interface Props {
    className?: string;
}

const loginUrl = process.env.REACT_APP_LOGIN_SERVER_URL || 'https://bipad.gov.np/ne/admin/';

function Navbar(props: Props) {
    const {
        className: classNameFromProps,
    } = props;

    /*
    const menuKeySelector = useCallback(
        (data: NavbarRoute) => data.name,
        [],
    );

    const menuRendererParams = useCallback(
        (key: string, data: NavbarRoute) => ({
            children: data.title,
            link: data.path,
            disabled: data.disabled,
        }),
        [],
    );
    */

    const className = _cs(
        classNameFromProps,
        styles.navbar,
    );

    const { lang, setLang, debug } = useContext(LanguageContext);
    const { theme, setSelectedTheme } = useContext(ThemeContext);
    const themeStatus = theme === 'dark';
    const setThemeStatus = useCallback((status) => {
        if (status) {
            setSelectedTheme('dark');
        } else {
            setSelectedTheme('light');
        }
    }, [setSelectedTheme]);

    return (
        <nav className={_cs(className, 'navbar')}>
            <Link
                to="/"
                className={styles.appBrand}
            >
                <img
                    className={styles.logo}
                    src={logo}
                    alt="Government of nepal"
                />
                <div className={styles.name}>
                    <div className={styles.gon}>
                        {translate(t.navbar.governmentLabel, lang, debug)}
                    </div>
                    <div className={styles.appTitle}>
                        {translate(t.navbar.ministryLabel, lang, debug)}
                    </div>
                    <div className={styles.ndrrma}>
                        {translate(t.navbar.ndrrmaLabel, lang, debug)}
                    </div>
                </div>
            </Link>
            {/*
                <ListView
                    className={styles.menu}
                    data={pages}
                    keySelector={menuKeySelector}
                    renderer={MenuItem}
                    rendererParams={menuRendererParams}
                    emptyComponent={EmptyComponent}
                />
            */}
            <div className={styles.rightContainer}>
                <Switch
                    className={styles.themeSwitch}
                    checked={themeStatus}
                    height={20}
                    width={40}
                    onChange={setThemeStatus}
                    offColor="#f5b312"
                    onColor="#00468c"
                    checkedIcon={(
                        <Icon
                            className={styles.switchIcon}
                            name="moon"
                        />
                    )}
                    uncheckedIcon={(
                        <Icon
                            className={styles.switchIcon}
                            name="sun"
                        />
                    )}
                />
                <SegmentInput
                    className={styles.languageSwitch}
                    options={langOptions}
                    keySelector={langKeySelector}
                    labelSelector={langLabelSelector}
                    value={lang}
                    onChange={setLang}
                    showLabel={false}
                    showHintAndError={false}
                />
                <a
                    className={styles.loginButton}
                    target="_blank"
                    href={loginUrl}
                    rel="noopener noreferrer"
                >
                    {translate(t.navbar.loginLabel, lang, debug)}
                </a>
            </div>
        </nav>
    );
}

export default Navbar;
