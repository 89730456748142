import React from 'react';
import { isNotDefined } from '@togglecorp/fujs';

export type Lang = 'en' | 'np';

export interface Translation {
    en: string;
    np?: string;
}

export interface LangOption {
    key: Lang;
    label: string;
}

export const langOptions: LangOption[] = [
    {
        key: 'en',
        label: 'en',
    },
    {
        key: 'np',
        label: 'ने.',
    },
];

export const langKeySelector = (d: LangOption) => d.key;
export const langLabelSelector = (d: LangOption) => d.label;

export function translate(translation: Translation, lang: Lang, debug = false) {
    const text = translation[lang];

    let style: React.CSSProperties | undefined;

    if (debug) {
        style = {
            ...style,
            backgroundColor: 'hotpink',
        };
    }

    if (isNotDefined(text)) {
        style = {
            ...style,
            textDecoration: 'line-through',
        };
    }

    if (style) {
        return (
            <span style={style}>
                {text || translation.en}
            </span>
        );
    }

    return text || translation.en;
}

export function translateRaw(translation: Translation, lang: Lang, debug = false) {
    const text = translation[lang];

    let prefix = '';

    if (isNotDefined(text)) {
        prefix = '?? ';
    }
    if (debug) {
        prefix = `${prefix}## `;
    }

    return `${prefix}${text || translation.en}`;
}
// const HealthInfrastructure = [
//     {
//         id: 1,
//         title: 'Infected Treatment',
//         titleNe: 'संक्रमितको उपचार',
//         data: [
//             {
//                 id: 1,
//                 title: 'Quarantined',
//                 titleNe: 'क्वारेन्टाईनमा बसेका',
//             },
//             {
//                 id: 2,
//                 title: 'Isolated',
//                 titleNe: 'आईसोलेसनमा बसेका',
//             },

//             {
//                 id: 3,
//                 title: 'Normal Treatment',
//                 titleNe: 'सामान्य उपचार',
//             },
//             {
//                 id: 4,
//                 title: 'ICU Treatment',
//                 titleNe: 'आईसीयू उपचार',
//             },
//             {
//                 id: 5,
//                 title: 'Patient in Ventilator',
//                 titleNe: 'भेन्टिलेटरमा बिरामी',
//             },
//         ],
//     },
//     { id: 2,
//         title: 'Health Infrastructure',
//         titleNe: 'स्वास्थ्य पूर्वाधार',
//         data: [
//             {
//                 id: 1,
//                 title: 'Oxygen Cylinder',
//                 titleNe: 'अक्सिजन सिलिन्डर',

//             }, {
//                 id: 2,
//                 title: 'Ambulance',
//                 titleNe: 'यम्बुलेन्स',
//             },
//             {
//                 id: 3,
//                 title: 'Ventilator',
//                 titleNe: 'भेन्टिलेटर',
//             },
//             {
//                 id: 4,
//                 title: 'ICU Bed',
//                 titleNe: 'आईसीयू बेड',
//             },
//             {
//                 id: 5,
//                 title: 'HDU Bed',
//                 titleNe: 'एचडियु बेड ',
//             },
//             {
//                 id: 6,
//                 title: 'Normal Bed',
//                 titleNe: 'सामान्य बेड',
//             },
//         ] },

//     {
//         id: 3,
//         title: 'Vaccination Record',
//         titleNe: 'खोपको रेकर्ड',
//         data: [
//             {
//                 id: 1,
//                 title: 'First Vaccination Dose',
//                 titleNe: 'पहिलो खोप लगाईएको',
//             }, {
//                 id: 2,
//                 title: 'Second Vaccination Dose',
//                 titleNe: 'दोस्रो खोप लगाईएको',
//             },
//         ],

//     },

//     {
//         id: 4,
//         title: 'PCR Test Condition',
//         titleNe: 'पीसीआर परीक्षणको अवस्था',
//         data: [
//             {
//                 id: 1,
//                 title: 'PCR Test',
//                 titleNe: 'पीसीआर परीक्षण',
//             },
//             {
//                 id: 2,
//                 title: 'PCR Positive',
//                 titleNe: 'पीसीआर पोजेटिभ',
//             },
//             {
//                 id: 3,
//                 title: 'Antigen Test',
//                 titleNe: ' एन्टिजेन परीक्षण',
//             },
//             {
//                 id: 4,
//                 title: 'Antigen Positive',
//                 titleNe: 'एन्टिजेन पोजेटिभ',
//             },

//         ],
//     }];

const translations = {
    // DASHBOARD PAGE

    categoryFilter: {
        en: 'Select Category',
        np: 'बर्ग चयन गर्नुहोस्',
    },
    leftpaneCardDataUpdated: {
        en: 'Data Last Updated',
        np: 'तथ्याङ्क अध्यावधिक गरिएको समय',
    },
    heading: {
        en: 'Nepal COVID-19 Dashboard',
        np: 'नेपाल कोभिड-१९ ड्यासबोर्ड',
    },
    returneesCountHeading: {
        np: 'Returnees count',
        en: 'फर्किएकाहरु संख्या',
    },
    leftPaneCard: {
        'Infected Treatment': {
            np: 'संक्रमितको उपचार',
            en: 'Infected Treatment',
        },
        'Health Infrastructure': {
            np: 'स्वास्थ्य पूर्वाधार',
            en: 'Health Infrastructure',
        },
        'Vaccination Record': {
            np: 'खोपको तथ्यांक',
            en: 'Vaccination Record',
        },
        'PCR Test Condition': {
            np: 'पीसीआर परीक्षणको अवस्था',
            en: 'PCR Test Condition',
        },
        Quarantined: {
            np: 'क्वारेन्टाईनमा बसेका',
            en: 'Quarantined',
        },
        Isolated: {
            np: 'आईसोलेसनमा बसेका',
            en: 'Isolated',
        },
        'Normal Treatment': {
            np: 'सामान्य उपचार',
            en: 'Normal Treatment',
        },
        'ICU Treatment': {
            np: 'आईसीयू उपचार',
            en: 'ICU Treatment',
        },
        'Patient in Ventilator': {
            np: 'भेन्टिलेटरमा बिरामी',
            en: 'Patient in Ventilator',
        },
        'Oxygen Cylinder': {
            en: 'Oxygen Cylinder',
            np: 'अक्सिजन सिलिन्डर',
        },
        Ambulance: {
            en: 'Ambulance',
            np: 'एम्बुलेन्स',
        },
        Ventilator: {
            en: 'Ventilator',
            np: 'भेन्टिलेटर',
        },
        'ICU Bed': {
            en: 'ICU Bed',
            np: 'आईसीयू बेड',
        },
        'HDU Bed': {
            en: 'HDU Bed',
            np: 'एचडियु बेड ',
        },
        'Normal Bed': {
            en: 'Normal Bed',
            np: 'सामान्य बेड',
        },
        'First Vaccination': {
            en: 'First Vaccination',
            np: 'पहिलो खोप लगाईएको',
        },
        'Second Vaccination': {
            en: 'Second Vaccination',
            np: 'दोस्रो खोप लगाईएको',
        },
        'PCR Test': {
            en: 'PCR Test',
            np: 'पीसीआर परीक्षण',
        },
        'PCR Positive': {
            en: 'PCR Positive',
            np: 'पीसीआर पोजेटिभ',
        },
        'Antigen Test': {
            en: 'Antigen Test',
            np: ' एन्टिजेन परीक्षण',
        },
        'Antigen Positive': {
            en: 'Antigen Positive',
            np: 'एन्टिजेन पोजेटिभ',
        },

    },
    totalConfirmedCases: {
        heading: {
            en: 'Total Confirmed Cases',
            np: 'कुल संक्रमित',
        },
        quarantinedLabel: {
            en: 'Quarantined',
            np: 'क्वारेन्टाईनमा बसेका',
        },
        isolatedLabel: {
            en: 'Isolated',
            np: 'आईसोलेसनमा बसेका',
        },
        activeLabel: {
            en: 'Active',
            np: 'उपचाररत',
        },
        recoveredLabel: {
            en: 'Recovered',
            np: 'निको भएका',
        },
        deathsLabel: {
            en: 'Deaths',
            np: 'मृत्यु',
        },
        swabTestedCount: {
            en: 'Total Swab Tested',
            np: 'कुल स्वाब परिक्षण गरिएको',
        },
        casesLastUpdated: {
            en: 'Cases last updated:',
            np: 'केस तथ्याङ्क अध्यावधिक गरिएको समय',
        },
        quarantineLastUpdated: {
            en: 'Quarantine last updated',
            np: 'क्वारेन्टाईन तथ्याङ्क अध्यावधिक गरिएको समय',
        },
    },
    Infrastructures: {
        LeftPaneHeading: {
            en: 'Health Infrastructure',
            np: 'Health',
        },
        oxygenCylinder: {
            en: 'Oxygen Cylinder',
            np: 'Oxygen Cylinder',
        },
        Ambulance: {
            en: 'Ambulance',
            np: 'Ambulance',
        },
        Ventilator: {
            en: 'Ventilator',
            np: 'Ventilator',
        },
        ICU: {
            en: 'ICU Bed',
            np: 'ICU Bed',
        },
        HDU: {
            en: 'HDU Bed',
            np: 'HDU Bed',
        },
        NormalBed: {
            en: 'Normal Bed',
            np: 'Normal Bed',
        },
        activeLabel: {
            en: 'Active',
            np: 'उपचाररत',
        },
        recoveredLabel: {
            en: 'Recovered',
            np: 'निको भएका',
        },
        deathsLabel: {
            en: 'Deaths',
            np: 'मृत्यु',
        },
        casesLastUpdated: {
            en: 'Cases last updated:',
            np: 'केस तथ्याङ्क अध्यावधिक गरिएको समय:',
        },
        quarantineLastUpdated: {
            en: 'Quarantine last updated:',
            np: 'क्वारेन्टाईन तथ्याङ्क अध्यावधिक गरिएको समय:',
        },
    },

    filters: {
        provinceLabel: {
            en: 'Province',
            np: 'प्रदेश',
        },
        districtLabel: {
            en: 'District',
            np: 'जिल्ला',
        },
        municipalityLabel: {
            en: 'Municipality',
            np: 'गाउँ/नगर पालिका',
        },
        wardLabel: {
            en: 'Ward',
            np: 'वडा नं.',
        },
    },
    map: {
        indicatorsLabel: {
            en: 'Indicators',
            np: 'तथ्याङ्कहरु',
        },
        showCasesLabel: {
            en: 'Show Cases',
            np: 'केसहरु देखाउनुहोस्',
        },
        showReturneesLabel: {
            en: 'Show Returnees',
            np: 'फर्किएकाहरु देखाउनुहोस्',
        },

        bubbleTooltip: {
            heading: {
                en: 'Returnees',
                np: 'फर्किएकाहरु',
            },
            countLabel: {
                en: 'Count',
                np: 'संख्या',
            },
            sourceLabel: {
                en: 'Source',
                np: 'स्रोत',
            },
            assessmentReportText: {
                en: 'Assessment Report',
                np: 'मूल्यांकन रिपोर्ट',
            },
        },
        caseTooltip: {
            currentStateLabel: {
                en: 'Current State',
                np: 'अहिलेको अवस्था',
            },
            genderLabel: {
                en: 'Gender',
                np: 'लिङ्ग ',
            },
            ageLabel: {
                en: 'Age',
                np: 'उमेर ',
            },
            dateOfReportLabel: {
                en: 'Date of Report',
                np: 'पुष्टी भएको मिति',
            },
            districtLabel: {
                en: 'District',
                np: 'जिल्ला',
            },
            nationalityLabel: {
                en: 'Native country',
                np: 'आफ्नो देश',
            },
            noteLabel: {
                en: 'Note',
                np: 'नोट',
            },
        },
    },
    healthInfrastructuresGraph: {
        heading: {
            en: 'Health Infrastructure',
            np: 'स्वास्थ्य पूर्वाधार',
        },
    },
    quarantinedNumbers: {
        heading: {
            en: 'Quarantined Numbers',
            np: 'क्वारेन्टाईनमा बसेकाको संख्या',
        },
        quarantinedLabel: {
            en: 'Quarantined',
            np: 'क्वारेन्टाईनमा बसेका',
        },
    },
    returneesByCountry: {
        heading: {
            en: 'Returnees by Country',
            np: 'देश अनुसारको विदेशबाट नेपाल आएकाको संख्या',
        },
    },
    footer: {
        question: {
            en: 'Have you recently travelled outside Nepal or know someone who has visited?',
            np: 'के तपाईँ वा तपाईँले चिन्नुभएको कोही भर्खरै नेपाल बाहिर जानु भएको थियो ?',
        },
        fillButtonLabel: {
            en: 'Fill self assessment form',
            np: 'स्व-मुल्याङ्कन फारम भर्नुहोस्',
        },
        furtherInfo: {
            en: 'For any enquiry with respect to Nepal COVID-19, you may email at ndrrma@gmail.com',
            np: 'नेपाल कोभिड-१९ सम्बन्धी थप जानकारी तथा प्रश्नको लागि ndrrma@gmail.com मा ईमेल गर्नुहोस्।',
        },
    },

    // SITUATION REPORT PAGE
    situationReport: {
        reportSection: {
            en: 'Report Section',
            np: 'रिपोर्ट खण्ड',
        },
        majorHighlights: {
            en: 'Major Highlights',
            np: 'मुख्य कुराहरु',
        },
        information: {
            en: 'Information Summary',
            np: 'जानकारी सारांश',
        },
        informationHeader: {
            en: 'Summary of information collected through various sources about Coronavirus(COVID-19)',
            np: 'कोरोना भाईरस (COVID-19) को बारेमा विभिन्न स्रोतहरु मार्फत संकलन गरिएको जानकारीको सारांश',
        },
        importantDocuments: {
            en: 'Important Documents',
            np: 'महत्त्वपूर्ण दस्तवेज',
        },
        reportNumberLabel: {
            en: 'Report Id',
            np: 'रिपोर्ट आईडी ',
        },
        dateSelection: {
            en: 'Situation report date',
            np: 'साइट रिपोर्ट मिति',
        },
        heading: {
            en: 'Coronavirus Disease 2019 (COVID-19)',
            np: 'कोरोनाभाइरस रोग २०१९ (कोभिड-19)',
        },
        subHeading: {
            en: 'National Situation Report',
            np: 'राष्ट्रिय स्थिति प्रतिवेदन',
        },
        dataSourceInfo: {
            en: 'Data as reported by District Administration Offices, Ministry of Health and Population, Local Government and other agencies by',
            np: 'गतेको प्रतिवेदन जिल्ला प्रशासन कार्यालय, स्वास्थ्य तथा जनसङ्ख्या मन्त्रालय र स्थानिय सरकार तथा अन्य निकायहरु मार्फत प्राप्त तथ्याङ्क',
        },
        figureLabel: {
            np: 'चित्र १. प्रादेशिक तहमा रहेका संक्रमितहरु तथा क्वारेन्टाइनमा रहेकाको तथ्याङ्क देखिने नक्शा',
            en: 'Figure 1. Provinces showing the quarantined numbers and confirmed cases in different provincial levels',
        },
        settingsHeader: {
            en: 'Settings',
            np: 'सेटिंग्स',
        },
        languageSelection: {
            en: 'Language',
            np: 'भाषा',
        },
        printButton: {
            en: 'Print',
            np: 'प्रिन्ट गर्नुहोस्',
        },

        summary: {
            heading: {
                en: 'Situation in numbers',
                np: 'तथ्याङ्कमा अवस्था',
            },
            description: {
                en: 'Total cases and isolation numbers (new in last 24 hours)',
                np: 'हालसम्मको जम्मा केसहरु तथा आइसोलेसनमा रहेकाको सङ्ख्याहरु (२४ घण्टा भित्रको थप नयाँ)',
            },
            totalCases: {
                en: 'Total Cases',
                np: 'जम्मा केसहरु',
            },
            totalRecovered: {
                en: 'Total Recovered',
                np: 'ठिक भई फर्केको',
            },
            totalIsolated: {
                en: 'Total Isolated',
                np: 'आईसोलेसनमा',
            },
            newCases: {
                en: 'New Cases',
            },
            newRecovered: {
                en: 'New Recovered',
                np: 'नयाँ ठीक भाई फर्केको ',
            },
        },

        healthIndicator: {
            returnees: {
                en: 'Returnees',
                np: 'नेपाल आएको',
            },
            entriesDenied: {
                en: 'Entries Denied',
                np: 'फिर्ता गरिएको',
            },
            swabCollected: {
                en: 'Swab Collected',
                np: 'स्वाब लिइएको',
            },
            swabTested: {
                en: 'Swab Tested',
                np: 'स्वाब टेष्ट गरिएको',
            },
            isolationBed: {
                en: 'Isolation Bed',
                np: 'इसोलसन खाटहरु',
            },

            quarantineBed: {
                en: 'Quarantine Bed',
                np: 'क्वारेन्टाईन खाटहरु',
            },
            quarantined: {
                en: 'Quarantined',
                np: 'क्वारेन्टाईनमा बसेका',
            },
            isolated: {
                en: 'Isolated',
                np: 'आईसोलेसनमा',
            },
            cases: {
                en: 'Cases',
                np: 'संक्रमित',
            },
            suspect: {
                en: 'Suspect',
                np: 'संकास्पद',
            },
            probable: {
                en: 'Probable',
                np: 'संभावित',
            },
            confirmed: {
                en: 'Confirmed',
                np: 'संक्रमित',
            },
            casesLocalTransmission: {
                en: 'Local Transmission',
                np: 'स्थानिय',
            },
            casesImported: {
                en: 'Imported',
                np: 'आयाति त',
            },
            casesTotal: {
                en: 'Total',
                np: 'जम्मा',
            },
            recovered: {
                en: 'Recovered',
                np: 'निको भएको',
            },
            deaths: {
                en: 'Deaths',
                np: 'मृत्यु',
            },
        },

        resourceTable: {
            heading: {
                en: 'National and Provincial Statistics: Resources',
            },
            federal: {
                en: 'Federal',
            },
            province1: {
                en: 'Province 1',
            },
            province2: {
                en: 'Province 2',
            },
            bagmati: {
                en: 'Bagmati',
            },
            gandaki: {
                en: 'Gandaki',
            },
            karnali: {
                en: 'Karnali',
            },
            sudurpaschim: {
                en: 'Sudurpaschim',
            },
            total: {
                en: 'Total',
            },
            noOfHospitals: {
                en: 'Number of Hospitals',
            },
            noOfHealthPosts: {
                en: 'No of Health Posts',
            },
            noOfSHPs: {
                en: 'No of S.H.P.',
            },
            others: {
                en: 'Others',
            },
            noOfSpecialists: {
                en: 'No of Specialist (Doctors)',
            },
            noOfHealthWorkers: {
                en: 'No of Health Workers',
            },
            ambulance: {
                en: 'Ambulance',
            },
            ppe: {
                en: 'PPE',
            },
            resources: {
                en: 'Resources',
            },
        },
        dailySummaryTable: {
            heading: {
                en: 'Daily summary of information gathered from different sources at NEOC about (COVID-19)',
                np: '(COVID-19)को बारेमा NEOC मा विभिन्न स्रोतहरूबाट भेला भएका सूचनाहरूको दैनिक सारांश',
            },
            description: {
                en: 'Description',
                np: 'बिबरण',
            },
            count: {
                en: 'Count',
                np: 'तथ्याङ्क',
            },
            remarks: {
                en: 'Remarks',
                np: 'टिप्पणी',
            },
            totalCase: {
                en: 'Total Cases',
                np: 'जाच गरिएको सांख्या',
            },
            totalIsolated: {
                en: 'Total Isolated',
                np: 'कुल अलग केसहरु',
            },
            totalRecovered: {
                en: 'Total Recovered',
                np: 'कुल रिकभरी भएका केसहरू',
            },
            newCase: {
                en: 'New Cases',
                np: 'नयाँ केसहरू',
            },
            newRecovered: {
                en: 'New Recovered Cases',
                np: 'नयाँ रिकभरी भएका केसहरू',
            },
            testedCount: {
                en: 'Number of People Tested',
                np: 'परीक्षण गरिएको व्यक्तिहरूको संख्या',
            },
            quarantineBedCount: {
                en: 'Quarantine Bed Count',
                np: 'क्वारेन्टाईन ओछ्यान संख्या',
            },
            reliefProvidedTodayCount: {
                en: 'Relief Provided Today',
                np: 'आज प्रदान गरियो राहत',
            },
            reliefProvidedCount: {
                en: 'Relief Provided Count',
                np: 'राहत प्रदान गन्ती',
            },
            quarantineTotalCount: {
                en: 'Quarantined Peple',
                np: 'क्वारेन्टाईनमा बसेका संख्या',
            },
            quarantineNormalCount: {
                en: 'Quarantined Count (Normal)',
                np: 'क्वारेन्टाईनमा बसेका संख्या (सामान्य)',
            },
            quarantineSickCount: {
                en: 'Quarantined Count (Sick)',
                np: 'क्वारेन्टाईनमा बसेका संख्या (बिरामी)',
            },
            quarantineReleasedCount: {
                en: 'Released from Quarantine',
                np: 'क्वारेन्टाईनबाट मुक्त भएका व्यक्तिहरूको संख्या',
            },
            isolationBedCount: {
                en: 'Isolation Bed Count',
                np: 'आईसोलेसन ओछ्यान संख्या',
            },
            isolationSickCount: {
                en: 'People Sick in Isolation',
                np: 'आईसोलेसनमा बिरामी संख्या',
            },
            isolationReleasedCount: {
                en: 'People Released from Isolation',
                np: 'आईसोलेसनबाट मुक्त भएका व्यक्तिहरूको संख्या',
            },
            totalQuarantineCount: {
                en: 'Total Number of People Quarantined',
                np: 'क्वारेन्टाईनमा बसेका संख्या (बिरामी)',
            },
            totalQuarantineReleasedCount: {
                en: 'Total Number of People Released form Quarantine',
                np: 'क्वारेन्टाईनबाट मुक्त भएका कुल व्यक्तिहरूको संख्या',
            },
            ppeCount: {
                en: 'Number of PPE',
                np: 'पीपीई को संख्या',
            },
            newPcrTestCount: {
                en: 'New PCR Test',
                np: 'नयाँ पीसीआर परीक्षणको संख्या',
            },
            totalPcrTestCount: {
                en: 'Total PCR Tested',
                np: 'कुल पीसीआर परीक्षणको संख्या',
            },
            availablePcrCount: {
                en: 'Available PCR',
                np: 'उपलब्ध पीसीआर परीक्षण संख्या',
            },
            newRdtTestCount: {
                en: 'New RDT Test',
                np: 'नयाँ RDT परीक्षण संख्या',
            },
            totalRdtTestCount: {
                en: 'Total RDT Test',
                np: 'कुल RDT परीक्षणको संख्या',
            },
            nepaleseInIndiaBorderTerritoryCount: {
                en: 'Total Number of Nepalese in India Border',
                np: 'भारत सीमामा नेपालीहरूको कुल संख्या',
            },
            indiansInNepalBorderTerritoryCount: {
                en: 'Total Number of Indians in Nepal Border',
                np: 'नेपाल सीमामा भारतीयहरुको कुल संख्या',
            },
            outboundTruckCount: {
                en: 'Outbound Trucks',
                np: 'नेपाल छोड्ने ट्रकहरूको संख्या',
            },
            inboundTruckCount: {
                en: 'Inbound Trucs',
                np: 'नेपालमा आएको ट्रकहरूको संख्या',
            },
            peopleDisobeyingCount: {
                en: 'People Disobeying Lockdown',
                np: 'लकडाउड अवज्ञा गर्ने व्यक्तिहरूको संख्या',
            },
            vehiclesDisobeyingCount: {
                en: 'Vehicles Disobeying Lockdown',
                np: 'लकडाउड अवज्ञा गर्ने सवारीको संख्या',
            },
            aboardReturneesCount: {
                en: 'Abroad Returnees',
                np: 'मानिसहरू जो विदेशबाट नेपाल फर्के',
            },
        },
        districtTable: {
            heading: {
                en: 'Record of Coronavirus (COVID-19)',
                np: 'कोरोनाभाइरस(COVID-19) को रेकर्ड',
            },
            date: {
                en: 'Date ',
                np: 'मिति ',
            },
            district: {
                en: 'District',
                np: 'जिल्ला',
            },
            province: {
                en: 'Province',
                np: 'प्रदेश',
            },
            yes: {
                en: 'Yes',
                np: 'भएको',
            },
            no: {
                en: 'No',
                np: 'नभएको',
            },
            testedCount: {
                en: 'Tested Count',
                np: 'चेकअप गरेको जम्मा संख्या',
            },
            releasedCount: {
                en: 'Released Count',
                np: 'फिर्ता गरेको जम्मा संख्या',
            },
            quarantineBedCount: {
                en: 'Quarantine Bed Count',
                np: 'क्वारेन्टाईन बेड संख्या',
            },
            quarantinedMaleCount: {
                en: 'Males in Quarantine',
                np: 'क्वारेन्टाइनमा पुरुष संख्या',
            },
            quarantinedFemaleCount: {
                en: 'Females in Quarantine',
                np: 'क्वारेन्टाइनमा महिला संख्या',
            },
            quarantinedCount: {
                en: 'Total People in Quarantine',
                np: 'क्वारेन्टाइनमा कुल मान्छे',
            },
            quarantinedSickCount: {
                en: 'Quarantine Sick Count',
                np: 'क्वारेन्टाइनमा बिरामी संख्या',
            },
            quarantinedReleaseCount: {
                en: 'Quarantine Release Count',
                np: 'क्वारेन्टाईनबाट मुक्त भएका व्यक्तिहरूको संख्या',
            },
            hasQuarantinedSickFoodService: {
                en: 'Food Service for Sick People in Quarantine',
                np: 'क्वारेन्टाइनमा बिरामी व्यक्तिहरूको लागि खाना सेवा',
            },
            isolatedBedCount: {
                en: 'Isolation Bed Count',
                np: 'आईसोलेसन ओछ्यान संख्या',
            },
            isolatedFemaleCount: {
                en: 'Females in Isolation',
                np: 'आईसोलेसनमा महिला संख्या',
            },
            isolatedMaleCount: {
                en: 'Males in Isolation',
                np: 'आईसोलेसनमा पुरुष संख्या',
            },
            isolatedCount: {
                en: 'Total People in Isolation',
                np: 'आईसोलेसनमा कुल मान्छे संख्या',
            },
            isolatedSickCount: {
                en: 'Sick People in Isolation',
                np: 'आईसोलेसनमा बिरामी संख्या',
            },
            isolatedReleaseCount: {
                en: 'Released from Isolation',
                np: 'आईसोलेसनबाट मुक्त भएका व्यक्तिहरूको संख्या',
            },
            swabCollectedCount: {
                en: 'Total Swab Collected',
                np: 'जम्मा swab संकलन',
            },
            swabTestedCount: {
                en: 'Total Swab Tested',
                np: 'परीक्षण गरिएको swab को कुल संख्या',
            },
            ppeCount: {
                en: 'Total Number of PPE',
                np: 'PPE को कुल संख्या',
            },
            reliefProvidedCount: {
                en: 'Total Relief Provided',
                np: 'प्रदान गरिएको राहतको कुल संख्या',
            },
            remarks: {
                en: 'Remarks',
                np: 'टिप्पणी',
            },
        },
        provinceTable: {
            heading: {
                en: 'National and Provincial Statistics: Health Indicators',
                np: 'राष्ट्रिय तथा प्रादेशिक स्तरको तथ्याङ्कः स्वास्थ्य तथ्याङ्कहरु',
            },
            region: {
                en: 'Region',
                np: 'प्रदेश',
            },
        },
    },

    indicatorOptions: {
        swabTestedCount: {
            en: 'Total Swab Tested',
            np: 'कुल स्वाब परिक्षण गरिएको',
        },
        quarantinedCount: {
            en: 'Quarantine',
            np: 'क्वारेन्टाईनमा बसेका',
        },
        isolatedCount: {
            en: 'Home Isolation',
            np: 'आईसोलेसनमा बसेका',
        },
        normalPatientCount: {
            en: 'Normal Patient',
            np: 'सामान्य बिरामी',
        },
        icuPatientCount: {
            en: 'ICU Patient',
            np: 'आईसीयू बिरामी',
        },
        vantilatorPatientCount: {
            en: 'Ventilator Patient',
            np: 'भेन्टिलेटर बिरामी',
        },
        oxygenCylinderCount: {
            en: 'Oxygen Cylinder',
            np: 'अक्सिजन सिलिन्डर',
        },
        ambulanceCount: {
            en: 'Ambulance',
            np: 'एंबुलेंस',
        },
        ventilatorCount: {
            en: 'Ventilator',
            np: 'भेन्टिलेटर',
        },

        icuCount: {
            en: 'ICU Bed',
            np: 'आईसीयू बेड',
        },
        hduBedCount: {
            en: 'HDU Bed',
            np: 'HDU बेड',
        },
        normalBedCount: {
            en: 'Normal Bed',
            np: 'सामान्य बेड',
        },
        firstVaccinatedCount: {
            en: 'First Vaccinated',
            np: 'पहिलो खोप लगाउने',
        },
        secondVaccinatedCount: {
            en: 'Second Vaccinated',
            np: 'दोस्रो खोप लगाउने ',
        },
        pcrTestCount: {
            en: 'PCR Test',
            np: 'पीसीआर परीक्षण',
        },
        pcrPositiveCount: {
            en: 'PCR Positive Cases',
            np: 'पीसीआर पोजेटिभ केस',
        },
        antigenTestCount: {
            en: 'Antigen Test',
            np: '',
        },
        antigenPositiveCount: {
            en: 'Antigen Positive Cases',
            np: '',
        },

    },

    navbar: {
        governmentLabel: {
            en: 'Government of Nepal',
            np: 'नेपाल सरकार',
        },
        ministryLabel: {
            en: 'Ministry of Home Affairs',
            np: 'गृह मन्त्रालय',
        },
        ndrrmaLabel: {
            en: 'National Disaster Risk Reduction and Management Authority (NDRRMA)',
            np: 'राष्ट्रिय विपद् जोखिम न्यूनीकरण तथा व्यवस्थापन प्राधिकरण',
        },

        neocLabel: {
            en: 'National Emergency Operation Center (NEOC)',
            np: 'राष्ट्रिय आपत्कालीन कार्य सञ्चालन केन्द्र',
        },

        loginLabel: {
            en: 'Login',
            np: 'लग इन',
        },
    },
    dataNotAvailable: {
        label: {
            en: 'Data not available',
            np: 'डाटा उपलब्ध छैन',
        },
    },
    selectInput: {
        placeholder: {
            en: 'Select an option',
            np: 'विकल्प छान्नुहोस्',
        },
    },
    timeline: {
        categoryFilterLabel: {
            en: 'Category',
            np: 'वर्ग',
        },
        download: {
            en: 'Download',
            np: 'डाउनलोड गर्नुहोस् ',
        },
    },
    lastUpdatedDate: {
        defaultLabel: {
            en: 'Last Updated:',
            np: 'अध्यावधिक गरिएको समय:',
        },
    },

    documents: {
        heading: {
            en: 'Documents',
            np: 'रिपोर्टहरू',
        },

        goToTimelineButtonLabel: {
            en: 'View all documents',
            np: 'सबै रिपोर्टहरू हेर्नुहोस्',
        },

        download: {
            en: 'Download',
            np: 'डाउनलोड गर्नुहोस् ',
        },
    },
};

export default translations;
