import React, { useEffect } from 'react';

import LanguageContext from '#components/LanguageContext';
import { Lang } from '#constants/translations';
import ThemeContext from '#components/ThemeContext';
import { Theme, setTheme } from '#constants/themes';
import { useStoredState } from '#hooks/index';

import Multiplexer from './Multiplexer';

/* Loads required info from server */
function App() {
    const pending = false;
    const [lang, setLang] = useStoredState<Lang>('lang', 'en');
    const [theme, setSelectedTheme] = useStoredState<Theme>('theme', 'light');

    useEffect(() => {
        setTheme(theme);
    }, [theme]);

    return (
        <LanguageContext.Provider
            value={{
                lang,
                setLang,
                debug: false,
            }}
        >
            <ThemeContext.Provider
                value={{
                    theme,
                    setSelectedTheme,
                }}
            >
                <Multiplexer
                    pending={pending}
                />
            </ThemeContext.Provider>
        </LanguageContext.Provider>
    );
}

export default App;
